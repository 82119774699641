function App() {
  return (
    <div className="App">
      <div style={{ textAlign: 'center', color: 'white', marginTop: '128px', fontSize: '3rem' }}>
        <h1>Andrew Glen</h1>
      </div>
    </div>
  );
}

export default App;
